import { FC } from "react"
import { Route, Routes } from "react-router-dom"

import { UDTreat } from "@/UDTreat/UDTreat"

const ClinicalModule: FC = () => {
  return (
    <Routes>
      <Route path={"treat"} element={<UDTreat />} />
    </Routes>
  )
}

export default ClinicalModule
