import React, { useState } from "react"
import { SketchPicker } from "react-color"
import { Box } from "@mui/material"
import { caseManagement } from "@/gluelayer"

const ColorPicker: React.FC = () => {
  const [color, setColor] = useState({ r: 255, g: 255, b: 255 })
  const [displayColorPicker, setDisplayColorPicker] = useState(false)

  React.useEffect(() => {
    const displayPicker = localStorage.getItem("displayColorPicker")
    if (displayPicker) {
      setDisplayColorPicker(JSON.parse(displayPicker))
    }
  }, [])

  const handleChangeComplete = (color: any) => {
    setColor(color.rgb)
    const rgbColor = color.rgb
    const normalizedColor = {
      r: parseFloat((rgbColor.r / 255).toFixed(2)),
      g: parseFloat((rgbColor.g / 255).toFixed(2)),
      b: parseFloat((rgbColor.b / 255).toFixed(2)),
    }
    caseManagement.setBackgroundColor(
      normalizedColor.r,
      normalizedColor.g,
      normalizedColor.b,
    )
  }

  return (
    displayColorPicker && (
      <Box
        sx={{
          position: "absolute",
          bottom: 20,
          right: 20,
          margin: "20px",
          zIndex: 1000,
        }}
      >
        <SketchPicker color={color} onChangeComplete={handleChangeComplete} />
        <div
          style={{
            marginTop: "20px",
            width: "100px",
            height: "100px",
            backgroundColor: `rgb(${color.r}, ${color.g}, ${color.b})`,
          }}
        />
      </Box>
    )
  )
}

export default ColorPicker
