import {
  Box,
  Button,
  Stack,
  ToggleButton,
  Tooltip,
  useTheme,
} from "@mui/material"
import Correction2 from "../assets/left/correction/2.svg"
import Correction3 from "../assets/left/correction/3.svg"
import Correction4 from "../assets/left/correction/4.svg"
import Correction5 from "../assets/left/correction/5.svg"
import Correction2_2 from "../assets/left/correction/2_2.svg"
import Correction3_3 from "../assets/left/correction/3_3.svg"
import Correction4_4 from "../assets/left/correction/4_4.svg"
import Correction5_5 from "../assets/left/correction/5_5.svg"
import { ReactSVG } from "react-svg"
import { useEffect, useState } from "react"
import { wasmModule } from "@/gluelayer"

const CorrectionTool = () => {
  const theme = useTheme()

  const [tool, setTool] = useState("")

  const handleToolChange = (
    event: React.MouseEvent<HTMLElement>,
    newTool: string,
  ) => {
    if (tool === newTool) {
      setTool("")
      return
    }
    setTool(newTool)
  }

  useEffect(() => {
    return () => {
      wasmModule.moduleManager.SwitchModuleOrientationAdjustment(false)
    }
  }, [])

  useEffect(() => {
    if (!wasmModule.isInit) return
    if (tool === "Tool3") {
      setShowSaveButton(true)
      wasmModule.moduleManager.SwitchModuleOrientationAdjustment(true)
      return
    }
    setShowSaveButton(false)
    wasmModule.moduleManager.SwitchModuleOrientationAdjustment(false)
  }, [tool])

  const [showSaveButton, setShowSaveButton] = useState(false)

  const saveButton = showSaveButton ? (
    <Button
      variant="contained"
      color="primary"
      sx={{
        position: "absolute",
        top: "30%",
        left: 164,
        // transform: "translateX(-50%)",
        zIndex: 1100,
      }}
      onClick={() => {
        const oriModule =
          wasmModule.moduleManager.GetModuleOrientationAdjustment()
        oriModule.SaveOrientation()
      }}
    >
      Save
    </Button>
  ) : null

  return (
    <>
      <Box
        sx={{
          left: 84,
          top: 104,
          borderRadius: "8px",
          backgroundColor: theme.transBkColor?.light,
          maxHeight: `calc(100% - 114px)`,
          boxShadow: 6,
          overflow: "hidden",
          position: "relative",
          zIndex: 1000,
          width: "150px",
        }}
      >
        <Stack spacing={1} direction="row">
          <Tooltip
            onClick={(e) => handleToolChange(e, "Tool1")}
            title="Tool1"
            placement="top"
          >
            <ToggleButton
              sx={{
                border: "none",
                width: "240px",
                height: "80px",
              }}
              value={"Tool1"}
            >
              <ReactSVG
                style={{ width: "100%", margin: "auto" }}
                src={tool === "Tool1" ? Correction2_2 : Correction2}
              />
            </ToggleButton>
          </Tooltip>
          <Tooltip
            onClick={(e) => handleToolChange(e, "Tool2")}
            title="Tool2"
            placement="top"
          >
            <ToggleButton
              sx={{ border: "none", width: "240px", height: "80px" }}
              value={"Tool2"}
            >
              <ReactSVG
                style={{ width: "100%", margin: "auto" }}
                src={tool === "Tool2" ? Correction3_3 : Correction3}
              />
            </ToggleButton>
          </Tooltip>
        </Stack>
        <Stack spacing={1} direction="row">
          <Tooltip
            onClick={(e) => handleToolChange(e, "Tool3")}
            title="Tool3"
            placement="bottom"
          >
            <ToggleButton
              sx={{ border: "none", width: "240px", height: "80px" }}
              value={"Tool3"}
            >
              <ReactSVG
                style={{ width: "100%", margin: "auto" }}
                src={tool === "Tool3" ? Correction4_4 : Correction4}
              />
            </ToggleButton>
          </Tooltip>
          <Tooltip
            onClick={(e) => handleToolChange(e, "Tool4")}
            title="Tool4"
            placement="bottom"
          >
            <ToggleButton
              sx={{ border: "none", width: "240px", height: "80px" }}
              value={"Tool4"}
            >
              <ReactSVG
                style={{ width: "100%", margin: "auto" }}
                src={tool === "Tool4" ? Correction5_5 : Correction5}
              />
            </ToggleButton>
          </Tooltip>
        </Stack>
      </Box>
      {saveButton}
    </>
  )
}

export default CorrectionTool
