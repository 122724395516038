import type { FC } from "react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Button } from "@mui/material"

import Drag from "../../../../components/Drag/Drag"

import { emptyData, lowTmp, topConfig, upTmp } from "./columns"
import Close from "../../../../assets/svgs/close.svg"
import "./boltonPanel.scss"
import SvgBtn from "@/components/DarkMode/SvgButton/svgbtn"
const BoltonPanel: FC = ({
  data,
  setBoltonPanelShow,
  boltonPanelShow,
  closeToothSize,
}) => {
  const { t } = useTranslation("common")
  const [topData, setTopData] = useState([
    { percent: "", size: "" },
    { percent: "", size: "" },
  ])
  const [upData, setUpData] = useState(emptyData)
  const [downData, setDownData] = useState(emptyData)
  const [data_3_3, setData33] = useState<string>("")
  const [data_6_6, setData66] = useState<string>("")
  useEffect(() => {
    if (data) {
      const {
        boltonIndex3_3,
        diffience3_3,
        boltonIndex6_6,
        diffience6_6,
        boltonData,
      } = data
      const percent_3 = `${boltonIndex3_3}%`
      const percent_6 = `${boltonIndex6_6}%`
      const size_3 = `${diffience3_3} mm`
      const size_6 = `${diffience6_6} mm`
      setTopData([
        { percent: percent_3, size: size_3 },
        { percent: percent_6, size: size_6 },
      ])
      const dis3_3 = 77.2 - +boltonIndex3_3
      if (dis3_3 > 0.22) {
        const data33 = `Maxillary Excess 3-3: ${diffience3_3}mm`
        setData33(data33)
      } else if (dis3_3 < -0.22) {
        const data33 = `Mandibular Excess 3-3: ${diffience3_3}mm`
        setData33(data33)
      }
      const dis6_6 = 91.3 - +boltonIndex6_6
      if (dis6_6 > 0.26) {
        const data66 = `Maxillary Excess 6-6: ${diffience6_6}mm`
        setData66(data66)
      } else if (dis6_6 < -0.26) {
        const data66 = `Mandibular Excess 6-6: ${diffience6_6}mm`
        setData66(data66)
      }
      // The id corresponds to the name of the relationship, to facilitate the later debugger
      // const tmp = []
      // for (let i = 1; i < 33; i++) {
      //   const obj = {}
      //   const name = getName(i)
      //   obj.name = name
      //   obj.id = i
      //   tmp.push(obj)
      // }
      //up
      console.log(
        "9988",
        boltonData.get(15) || boltonData.get(515) || boltonData.get(715) || 0,
      )
      const UR1 =
        boltonData.get(8) || boltonData.get(508) || boltonData.get(708) || 0
      const UL1 =
        boltonData.get(9) || boltonData.get(509) || boltonData.get(709) || 0
      const UR2 =
        boltonData.get(7) || boltonData.get(507) || boltonData.get(707) || 0
      const UL2 =
        boltonData.get(10) || boltonData.get(510) || boltonData.get(710) || 0
      const UR3 =
        boltonData.get(6) || boltonData.get(506) || boltonData.get(706) || 0
      const UL3 =
        boltonData.get(11) || boltonData.get(511) || boltonData.get(711) || 0
      const UR4 =
        boltonData.get(5) || boltonData.get(505) || boltonData.get(705) || 0
      const UL4 =
        boltonData.get(12) || boltonData.get(512) || boltonData.get(712) || 0
      const UR5 =
        boltonData.get(4) || boltonData.get(504) || boltonData.get(704) || 0
      const UL5 =
        boltonData.get(13) || boltonData.get(513) || boltonData.get(713) || 0
      const UR6 =
        boltonData.get(3) || boltonData.get(503) || boltonData.get(703) || 0
      const UL6 =
        boltonData.get(14) || boltonData.get(514) || boltonData.get(714) || 0
      //down
      const LR1 =
        boltonData.get(25) || boltonData.get(525) || boltonData.get(725) || 0
      const LL1 =
        boltonData.get(24) || boltonData.get(524) || boltonData.get(724) || 0
      const LR2 =
        boltonData.get(26) || boltonData.get(526) || boltonData.get(726) || 0
      const LL2 =
        boltonData.get(23) || boltonData.get(523) || boltonData.get(723) || 0
      const LR3 =
        boltonData.get(27) || boltonData.get(527) || boltonData.get(727) || 0
      const LL3 =
        boltonData.get(22) || boltonData.get(522) || boltonData.get(722) || 0
      const LR4 =
        boltonData.get(28) || boltonData.get(528) || boltonData.get(728) || 0
      const LL4 =
        boltonData.get(21) || boltonData.get(521) || boltonData.get(721) || 0
      const LR5 =
        boltonData.get(29) || boltonData.get(529) || boltonData.get(729) || 0
      const LL5 =
        boltonData.get(20) || boltonData.get(520) || boltonData.get(720) || 0
      const LR6 =
        boltonData.get(30) || boltonData.get(530) || boltonData.get(730) || 0
      const LL6 =
        boltonData.get(19) || boltonData.get(519) || boltonData.get(719) || 0
      const upTmp = [
        [UR1, UL1],
        [UR2, UL2],
        [UR3, UL3],
        [UR4, UL4],
        [UR5, UL5],
        [UR6, UL6],
      ]
      const downTmp = [
        [LR1, LL1],
        [LR2, LL2],
        [LR3, LL3],
        [LR4, LL4],
        [LR5, LL5],
        [LR6, LL6],
      ]
      setUpData(upTmp)
      setDownData(downTmp)
    }
  }, [data])
  return boltonPanelShow ? (
    <Drag
      DragSize={{ width: 390, height: 670 }}
      DragPosition={{
        x: 84,
        y: 110,
      }}
      // bounds={".treatMain"}
    >
      <Box className="boltonContainer">
        <div className="toolbar">
          <span className={"titleName"}>{`Bolton Analysis`}</span>
          {/* <Button
            onClick={() => {
              setBoltonPanelShow(false)
              closeToothSize()
            }}
            className="close"
          /> */}
          <SvgBtn
            boxSx={{
              borderRadius: "16px",
              marginTop: "8px",
              marginRight: "8px",
            }}
            svg={Close}
            clickFun={() => {
              setBoltonPanelShow(false)
              closeToothSize()
            }}
            svgColor="rgba(0,0,0,1)"
          ></SvgBtn>
        </div>
        <div className={"boltonTop"}>
          {/* <div className={"lineBox"}>
            <div className={"left"}></div>
            <div className={"right"}>
              <div className={"percent"}>{t("treat.boltonAnalysis")}</div>
              <div className={"size"}>{t("treat.boltonSize")}</div>
            </div>
          </div>
          {topConfig.map((i, j) => {
            return (
              <div key={i.key} className={"lineBox"}>
                <div className={"left"}>{i.titleRow}</div>
                <div className={"right"}>
                  <div className={"percent percentV"}>{topData[j].percent}</div>
                  <div className={"size sizeV"}>{topData[j].size}</div>
                </div>
              </div>
            )
          })} */}
          <div
            className={"lineBox"}
          >{`Bolton Analysis 3-3: ${topData[0].percent}  |  6-6: ${topData[1].percent}`}</div>
          {data_3_3 !== "" && <div className={"lineBox"}>{data_3_3}</div>}
          {data_6_6 !== "" && <div className={"lineBox"}>{data_6_6}</div>}
        </div>
        <div className={"content"}>
          <div className={"titles"}>
            <div className={"label"}>{t("treat.toothID")}</div>
            <div className={"label"}>{t("treat.R")}</div>
            <div className={"label"}>{t("treat.L")}</div>
          </div>
          <div className={"boltonBox"}>
            {upTmp.map((i, j) => {
              return (
                <div key={i.key} className={"lineWrap"}>
                  <div className={"lineValue"}>{i.label}</div>
                  <div className={"lineValue"}>{upData[j][0]}</div>
                  <div className={"lineValue"}>{upData[j][1]}</div>
                </div>
              )
            })}
          </div>
          <div className={"driver"}></div>
          <div className={"boltonBox"}>
            {lowTmp.map((i, j) => {
              return (
                <div key={i.key} className={"lineWrap"}>
                  <div className={"lineValue"}>{i.label}</div>
                  <div className={"lineValue"}>{downData[j][0]}</div>
                  <div className={"lineValue"}>{downData[j][1]}</div>
                </div>
              )
            })}
          </div>
        </div>
        <div className={"bottom"}></div>
      </Box>
    </Drag>
  ) : (
    <></>
  )
}
export default BoltonPanel
