import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"
import { createLogger } from "redux-logger"

import { autoSetupSlice } from "../../wasm3d/AutoSetupSlice"
import { autoStagingSlice } from "../../wasm3d/AutoStagingSlice"
import { manuStagingPercentSlice } from "../../wasm3d/CommonPages/ManuStagingPercentSlice"
import { toothInfoSlice } from "../../wasm3d/CommonPages/ToothInfoSlice"
import { udToothInfoSlice } from "@/UDTreat/udToothInfoSlice"
//UFlowChange
import { wasm3DServiceSlice } from "../../wasm3d/Wasm3DServiceSlice"

import { alertSlice } from "./slices/alert/alertSlice"
import { analyticSlice } from "./slices/AnalyticsSlice"
import { bannerServiceSlice } from "./slices/banners/bannerSlice"
import { BillingServiceSlice } from "./slices/billing"
import { BundleServiceSlice } from "./slices/bundle"
import { caseServiceSlice } from "./slices/case/caseSlice"
import { clinicalServiceSlice } from "./slices/clinical/clinicalSlice"
import { configSlice } from "./slices/ConfigSlice"
import { customPackagingSlice } from "./slices/customPackaging/customPackaging.slice"
import { doctorServiceSlice } from "./slices/doctors/doctorSlice"
import { navigationPopupSlice } from "./slices/navigationPopup/navigationPopupSlice"
import { OrderServiceSlice } from "./slices/order/orderSlice"
import { organizationServiceSlice } from "./slices/organization/organizationSlice"
import { patientServiceSlice } from "./slices/patients/patientSlice"
import { PricingServiceSlice } from "./slices/pricing/pricingSlice"
import { photographServiceSlice } from "./slices/records/photograph/photographSlice"
import { scansServiceSlice } from "./slices/records/scans/scansSlice"
import { xrayServiceSlice } from "./slices/records/xrays/xraysSlice"
import { formServiceSlice } from "./slices/uassist/formSlice"
import userServiceSlice from "./slices/user/userSlice"

import { udTreatSlice } from "@/UDTreat/udTreatSlice"
import { udSetupSlice } from "@/UDTreat/udSetupSlice"
import { cacheServiceSlice }  from "./slices/cache/cacheSlice"

const logger = createLogger({
  level: "info",
  collapsed: true,
})

export const store = configureStore({
  reducer: {
    userService: userServiceSlice,
    config: configSlice.reducer,
    analytic: analyticSlice.reducer,
    patientService: patientServiceSlice.reducer,
    clinicalService: clinicalServiceSlice.reducer,
    wasm3DServiceSlice: wasm3DServiceSlice.reducer,
    autoSetupSlice: autoSetupSlice.reducer,
    autoStagingSlice: autoStagingSlice.reducer,
    manuStagingPercentSlice: manuStagingPercentSlice.reducer,
    doctorService: doctorServiceSlice.reducer,
    toothInfoSlice: toothInfoSlice.reducer,
    PhotosService: photographServiceSlice.reducer,
    xrayService: xrayServiceSlice.reducer,
    scansService: scansServiceSlice.reducer,
    alert: alertSlice.reducer,
    navigationPopup: navigationPopupSlice.reducer,
    formService: formServiceSlice.reducer,
    orderService: OrderServiceSlice.reducer,
    bundleService: BundleServiceSlice.reducer,
    bannerService: bannerServiceSlice.reducer,
    caseService: caseServiceSlice.reducer,
    organizationService: organizationServiceSlice.reducer,
    billingService: BillingServiceSlice.reducer,
    customPackagingService: customPackagingSlice.reducer,
    PricingService: PricingServiceSlice.reducer,
    // for ud cloud 2.0
    udTreatService: udTreatSlice.reducer,
    udSetupService: udSetupSlice.reducer,
    udToothInfoService: udToothInfoSlice.reducer,
    // for caching files that downloaded from server.
    cacheService: cacheServiceSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV !== "test") {
      return getDefaultMiddleware({
        serializableCheck: false,
      }).concat(logger)
    } else {
      return getDefaultMiddleware({
        serializableCheck: false,
      })
    }
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export default store
