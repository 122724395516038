import DialogBox from "@/modules/Clinical/components/DialogBox/dialogBox"
import { useAppSelector } from "@/core/app/hooks"
import { UButton } from "@/components/index"
import { RootState } from "@/core/app/store"
export const ErrorDialogBox = (props: any) => {
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const errorContent = (type: string) => {
    switch (type) {
      case "failed":
        return "Presetup cloud computing fails."
      case "network error":
        return "Network error, please check your network connection."
      case "retry":
        return "Some errors occurred, please try again."
    }
  }
  return (
    <DialogBox
      open={props.isShow}
      title={"Pre-setup Error"}
      content={
        <span style={{ fontSize: "14px", letterSpacing: "0.17px" }}>
          {errorContent(props.errorType)}
        </span>
      }
      actions={
        <>
          <UButton
            onClickHandler={() => {
              props.setIsShowPresetupError(false)
            }}
            variant={"text"}
            btnText={"Cancel"}
          />
          {props.errorType !== "failed" && (
            <UButton
              onClickHandler={() => {
                props.setIsShowPresetupError(false)
                const udesign_json = JSON.parse(caseDetail.udesign_json)
                const hasUpper = udesign_json.upperUploaded
                const hasLower = udesign_json.lowerUploaded
                props.requestAiPresetup(hasUpper, hasLower, udesign_json)
              }}
              variant={"text"}
              btnText={"Retry"}
            /> 
          )}
        </>
      }
    />
  )
}
