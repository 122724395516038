import { useEffect, useState } from "react"
import { Box, Select, Typography, MenuItem } from "@mui/material"
import { refinementModule } from "../../gluelayer/core/modules/refinement";
import { treatView, caseManagement } from "@/gluelayer"
import { useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import {dateFormat} from "@/core/utils/formatters";

const testHistoryList = {
  original: "1/8/24" ,
  Refinement: "1/8/24" ,
  Retainer: "1/8/24" ,
}
let refinementHistoryList
const RefinementList = (props: any) => {
  const [historyList, setHistoryList] = useState([])
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const [currentRefinement, setCurrentRefinement] = useState({name: "", value: ""})
  const [lastRefinement, setLastRefinement] = useState({name: "", value: ""})
  const [showList, setShowList] = useState(false)
  const { refinementReady } = useAppSelector((state: RootState) => state.clinicalService)
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  useEffect(() => {
    if (caseDetail?.uassist_round > 0) {
      setShowList(true)

      let uassist_round = caseDetail?.uassist_round || 0
      let refinementHistoryList = []
      for (let i = 0, l = uassist_round; i <= l; i++) {
        if (i == 0) refinementHistoryList.push({name: "initial plan", value: i})
        else refinementHistoryList.push({name: `refinement ${i}`, value: i})
      }

      setHistoryList([...refinementHistoryList.reverse()])
      setCurrentRefinement(refinementHistoryList[0])
      props.onRefinementChange(refinementHistoryList.length-1)
      // //use test data for now
      // // refinementHistoryList = testHistoryList
      // const firstKey = Object.keys(refinementHistoryList)[0]
      // if (firstKey && refinementHistoryList[firstKey] !== ""){
      //   setCurrentRefinement({ name: firstKey, date: refinementHistoryList[firstKey]})
      //   setLastRefinement({ name: firstKey, date: refinementHistoryList[firstKey]})
      // }
      // else{
      //   setCurrentRefinement({ name: "initial plan", date: props.creatDate})
      //   setLastRefinement({ name: "initial plan", date: props.creatDate})
      // }
      // const tempList = []
      // for (const item in refinementHistoryList) {
      //   if (item !== "original")
      //     tempList.push({ name: item, date: refinementHistoryList[item] !== "" ? refinementHistoryList[item] : props.creatDate })
      // }
      // tempList.push({ name: "initial plan", date: props.creatDate })
      // setHistoryList([...tempList])
      // props.onRefinementChange(tempList.length - 1)
      // console.log("refinementHistoryList", tempList)
    }
  }, [props.creatDate, caseManagement.isCaseInit(), caseDetail, refinementReady])

  const handleChange = (event) => {
    setCurrentRefinement(event.target.value)
    let refinementNum = event.target.value.value
    props.onRefinementChange(refinementNum)
  }

  return (
    ( showList && props.isShow && (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "331px",
          marginBottom: "16px",
          boxShadow: "0px 3px 5px -2px #888888",
          ...props.listSX
        }}
      >
        <Select
          onChange={handleChange}
          value={currentRefinement}
          sx={{
            height: "30px",
            backgroundColor: "#F5F5F5",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}>
          {historyList.map((item) => (
            <MenuItem
              key={"historyListItem" + item.value}
              value={item}
              sx={{
                color: item.name === currentRefinement.name ? "#9C9C9C" : "#000"
              }}>
              {` #` + props.caseId + " | " + capitalizeFirstLetter(item.name) + " - " + dateFormat(caseDetail.createDate, "MM/DD/YY")}
            </MenuItem>
          ))}
        </Select>
      </Box>)
    )
  )
}
export default RefinementList
