import oktaAuthClient from "@/core/config/okta.config"
import { getAccessToken } from "@/core/config/okta.config"
import * as cryptoJS from "crypto-js"

/**
 * check is there a specified photo by photo-json
 * @param json
 * @returns {
 *  normalPosition: boolean
 *  xrayPosition: boolean
 * }
 */
export function checkIsThereASpecifiedPhoto(json: Object) {
  const ret = {
    normalPosition: false,
    xrayPosition: false,
  }
  if (!json) {
    return ret
  }

  const normalPosition = [
    "composite",
    "front_smile",
    "front_normal",
    "lateral_photo",
    "upper",
    "lower",
    "front",
    "left",
    "right",
  ]
  const xrayPositionType = ["x_lateral", "x_panorama"]

  Object.keys(json).forEach((key) => {
    const posType = json[key]
    if (key !== "thumbs" && posType) {
      if (normalPosition.includes(posType)) {
        ret.normalPosition = true
      } else if (xrayPositionType.includes(posType)) {
        ret.xrayPosition = true
      }
    }
  })

  return ret
}

/**
 * Convert a string to a hash code
 * @param str
 * @returns hash code
 */
export function getHashCodeFromString(str: string) {
  // convert filename to a hash code
  const hash = cryptoJS.SHA256(str).toString(cryptoJS.enc.Hex)
  return hash
}

/**
 * Convert a File object to a base64-encoded string
 * @param file
 * @returns
 */
export function fileToBase64(file): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result as string)
    }
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

/**
 * Convert a dataURL to a File
 * @param dataURI
 * @param fileName
 * @returns File
 */
export function dataURItoFile(dataURI, fileName) {
  const [dataDescription, base64Data] = dataURI.split(",")
  // 文件类型
  const mimetype = dataDescription.match(/:(.*?);/)[1]

  // 解码 base64 数据
  const decodedData = atob(base64Data)
  let n = decodedData.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = decodedData.charCodeAt(n)
  }

  return new File([u8arr], fileName, { type: mimetype })
}

/**
 * Compress a Image by a quality
 * @param file
 * @param options
 * {
 *  quality?:number,
 *  width?:number,
 *  height?:number,
 *  filename?:string
 * }
 * @returns a new File that been compressed
 */
export async function compressImageByCanvas(
  file,
  options: {
    quality?: number
    width?: number
    height?: number
    filename?: string
  } = {},
) {
  const { quality } = options
  let { width, height } = options

  const filename = options.filename ?? file.name

  let _resolve, _reject
  const promise = new Promise<File>((resolve, reject) => {
    _resolve = resolve
    _reject = reject
  })

  const img = new Image()
  img.onload = function () {
    // 如果只指定了宽度或高度，则另一个按比例缩放
    if (width && !height) {
      height = Math.round(img.height * (width / img.width))
    } else if (!width && height) {
      width = Math.round(img.width * (height / img.height))
    }

    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")

    // 设置 canvas 的宽高与图片一致
    canvas.width = width || img.width
    canvas.height = height || img.height

    // 在 canvas 上绘制图片
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

    // 获取压缩后的图片数据
    const compressedDataUrl = canvas.toDataURL("image/jpeg", quality)
    _resolve(dataURItoFile(compressedDataUrl, filename))
  }

  img.src = URL.createObjectURL(file)
  return promise
}

/**
 * Splits an array into chunks of a specified size.
 * 
 * @param array - The array to split.
 * @param size - The size of each chunk.
 * @returns result - An array of chunked arrays.
 */
const chunkArray = (array: File[], size: number) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result
}

/**
 * Function to upload files in batches and return the payloads
 * @returns - An object indicating the results of both upload operations payloads.
 */
// Function to upload files in batches and return the payloads
export const uploadFilesInChunksAndReturnPayloads = async (
  dispatch: Function,
  uploadPhotographsAction: any,
  uploadThumbnailAction: any,
  imageFiles: { [key: string]: File },
  thumbFiles: { [key: string]: File },
  keys: string[],
  refinementIndex: number,
  patientId: string,
  caseId: string
) => {
  const size: number = 10;
  // Split the files into batches of 10
  const imageFileChunks = chunkArray(keys.map(key => imageFiles[key]), size);
  const thumbFileChunks = chunkArray(keys.map(key => thumbFiles[key]), size);

  // Create arrays to store the responses
  const retPayload = [];
  const ret2Payload = [];

  // Function to upload a single batch of files
  const uploadBatch = async (
    imageBatch: File[],
    thumbBatch: File[],
    refinementIndex: number,
    patientId: string,
    caseId: string
  ) => {
    const ret = await dispatch(uploadPhotographsAction({
      patientId,
      caseId,
      files: imageBatch,
      refinementIndex: refinementIndex ?? 0
    }));

    const ret2 = await dispatch(uploadThumbnailAction({
      patientId,
      caseId,
      files: thumbBatch,
      refinementIndex: refinementIndex ?? 0
    }));

    // Check if any upload has an error
    const downloadErr1 = (ret as any).error;
    const downloadErr2 = (ret2 as any).error;

    if (downloadErr1 || downloadErr2) {
      downloadErr1 && console.warn('upload original image failed:', downloadErr1.message);
      downloadErr2 && console.warn('upload thumbnail image failed:', downloadErr2.message);
      return { error: true, ret, ret2 };
    }

    return { error: false, ret, ret2 };
  };

  // Upload in batches and store the results
  for (let i = 0; i < imageFileChunks.length; i++) {
    const result = await uploadBatch(
      imageFileChunks[i],
      thumbFileChunks[i],
      refinementIndex,
      patientId,
      caseId
    );
    if (result.error) return { retPayload: [], ret2Payload: [] }; // return empty if there's any error
    retPayload.push(...result.ret.payload);
    ret2Payload.push(...result.ret2.payload);
  }

  return { retPayload, ret2Payload };
};
