import { useEffect, useState } from "react"

import {
  Paper,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material"

import ChairsideIcon from "@mui/icons-material/QuickreplyOutlined"
import QuickPlanIcon from "@mui/icons-material/DocumentScannerOutlined"
import QuickStagingIcon from "@mui/icons-material/RectangleOutlined"
import QuickAuxIcon from "@mui/icons-material/AttachmentOutlined"
import ReviewModIcon from "@mui/icons-material/TouchAppOutlined"
import WeSetupIcon from "@mui/icons-material/Settings"
import WeStagingIcon from "@mui/icons-material/RouteOutlined"
import WeAttIcon from "@mui/icons-material/AttachmentOutlined"
import RxIcon from "@mui/icons-material/BookOutlined"
import OrderIcon from "@mui/icons-material/AddShoppingCartOutlined"
import WeTestIcon from "@mui/icons-material/PestControl"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { setCurRightPage, setOpenBiteCheck } from "../udTreatSlice"
import { useTheme } from "@mui/material/styles"
import {
  EArchType,
  toothMovement,
  viewControlInTreatment,
  wasmModule,
} from "@/gluelayer"

import NewAuxIcon from "../assets/right/attachment.svg"
import NewArchIcon from "../assets/right/arch_adjustment.svg"
import NewVerticalIcon from "../assets/right/vertical_adjustment.svg"

import {
  ArchAdjustmentIcon,
  AuxIcon,
  VerticalAdjustmentIcon,
} from "../top/RightToolBarIcon"
import { ReactSVG } from "react-svg"

export function RightToolBar(props: any) {
  const theme = useTheme()
  const { curCaseMode, curRightPage, switchViewEdit, curLeftPage } =
    useAppSelector((state: RootState) => state.udTreatService)
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)

  const dispatch = useAppDispatch()
  const [fastsmartAlert, setFastsmartAlert] = useState(false)
  const { curArch, openHistorySplitView, openBiteCheck } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextTool: string,
  ) => {
    if (nextTool === "fastsmart" || nextTool === "finetune") {
      const hasUpper = wasmModule.getArchModel(EArchType.UpArch)
      const hasLower = wasmModule.getArchModel(EArchType.LowArch)

      if (hasUpper && hasLower && curArch === "all") {
        setFastsmartAlert(true)
        return
      }
    }

    dispatch(setCurRightPage(nextTool))

    if (!wasmModule.isInit) return
    if (nextTool === "fastsmart") {
      if (openBiteCheck) {
        dispatch(setOpenBiteCheck(false))
        viewControlInTreatment.setOcclusionVisibility(false)
      }

      toothMovement.switchModuleFineTuneCircle(false)
      toothMovement.switchModuleFastSmartCircle(true)
    } else if (nextTool === "finetune") {
      if (openBiteCheck) {
        dispatch(setOpenBiteCheck(false))
        viewControlInTreatment.setOcclusionVisibility(false)
      }
      viewControlInTreatment.setOcclusionVisibility(false)
      toothMovement.switchModuleFastSmartCircle(false)
      toothMovement.switchModuleFineTuneCircle(true)
    } else {
      toothMovement.switchModuleFineTuneCircle(false)
      toothMovement.switchModuleFastSmartCircle(false)
    }
  }

  useEffect(() => {
    if (openBiteCheck) {
      if (curRightPage === "fastsmart" || curRightPage === "finetune") {
        dispatch(setCurRightPage(""))
      }
    }
  }, [openBiteCheck])

  useEffect(() => {
    dispatch(setCurRightPage(""))

    if (!switchViewEdit && wasmModule.isInit) {
      toothMovement.switchModuleFineTuneCircle(false)
      toothMovement.switchModuleFastSmartCircle(false)
    }
  }, [switchViewEdit])

  useEffect(() => {
    if (curLeftPage === "correctionTool") {
      dispatch(setCurRightPage(""))
      if (wasmModule.isInit) {
        toothMovement.switchModuleFineTuneCircle(false)
        toothMovement.switchModuleFastSmartCircle(false)
      }
    }
  }, [curLeftPage])
  return (
    !openHistorySplitView &&
    curLeftPage != "correctionTool" && (
      <Paper
        sx={{
          right: 16,
          top: 98,
          backgroundColor: theme.transBkColor.light,
          maxHeight: `calc(100% - 114px)`,
          overflow: "auto",
          "::-webkit-scrollbar": {
            width: "1px",
          },
          position: "absolute",
          borderRadius: "8px",
        }}
        elevation={6}
      >
        {switchViewEdit &&
          caseDetail.caseDisposition !== "ATTENTION_NEEDED" && (
            <ToggleButtonGroup
              orientation="vertical"
              value={curRightPage}
              exclusive
              onChange={handleChange}
            >
              <Tooltip title="UD Auxillary" placement="left">
                <ToggleButton
                  value="UDAux"
                  sx={{
                    border: "none",
                    borderRadius: "8px",
                    "&:hover": {
                      borderRadius: "8px",
                    },
                    "&.Mui-selected": {
                      borderRadius: "8px",
                    },
                  }}
                >
                  <ReactSVG
                    src={NewAuxIcon}
                    afterInjection={(svg) => {
                      const path = svg.getElementsByTagName("path")[0]
                      if (curRightPage === "UDAux") {
                        path?.setAttribute("fill", "#215ECD")
                        path?.setAttribute("fill-opacity", "1")
                      } else {
                        path?.setAttribute("fill", "black")
                        path?.setAttribute("fill-opacity", "0.56")
                      }
                    }}
                  ></ReactSVG>
                </ToggleButton>
              </Tooltip>

              <Tooltip title="Arch Adjustment" placement="left">
                <ToggleButton
                  value="fastsmart"
                  sx={{
                    border: "none",
                    borderRadius: "8px",
                    "&:hover": {
                      borderRadius: "8px",
                    },
                    "&.Mui-selected": {
                      borderRadius: "8px",
                    },
                  }}
                >
                  <ReactSVG
                    src={NewArchIcon}
                    afterInjection={(svg) => {
                      const path = svg.getElementsByTagName("path")[0]
                      if (curRightPage === "fastsmart") {
                        path?.setAttribute("fill", "#215ECD")
                        path?.setAttribute("fill-opacity", "1")
                      } else {
                        path?.setAttribute("fill", "black")
                        path?.setAttribute("fill-opacity", "0.56")
                      }
                    }}
                  ></ReactSVG>
                </ToggleButton>
              </Tooltip>
              <Tooltip title="Vertical Adjustment" placement="left">
                <ToggleButton
                  value="finetune"
                  sx={{
                    border: "none",
                    borderRadius: "8px",
                    "&:hover": {
                      borderRadius: "8px",
                    },
                    "&.Mui-selected": {
                      borderRadius: "8px",
                    },
                  }}
                >
                  <ReactSVG
                    src={NewVerticalIcon}
                    afterInjection={(svg) => {
                      const path = svg.getElementsByTagName("path")[0]
                      if (curRightPage === "finetune") {
                        path?.setAttribute("fill", "#215ECD")
                        path?.setAttribute("fill-opacity", "1")
                      } else {
                        path?.setAttribute("fill", "black")
                        path?.setAttribute("fill-opacity", "0.56")
                      }
                    }}
                  ></ReactSVG>
                </ToggleButton>
              </Tooltip>

              <Tooltip title="WeSetup" placement="left">
                <ToggleButton
                  value="WeSetup"
                  sx={{
                    border: "none",
                    borderRadius: "8px",
                    "&:hover": {
                      borderRadius: "8px",
                    },
                    "&.Mui-selected": {
                      borderRadius: "8px",
                    },
                  }}
                >
                  <ReactSVG
                    src={NewAuxIcon}
                    afterInjection={(svg) => {
                      const path = svg.getElementsByTagName("path")[0]
                      if (curRightPage === "WeSetup") {
                        path?.setAttribute("fill", "#215ECD")
                        path?.setAttribute("fill-opacity", "1")
                      } else {
                        path?.setAttribute("fill", "black")
                        path?.setAttribute("fill-opacity", "0.56")
                      }
                    }}
                  ></ReactSVG>
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          )}
        <Dialog open={fastsmartAlert}>
          {/* <DialogTitle></DialogTitle> */}
          <DialogContent>
            Please select a single arch to use this function.
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setFastsmartAlert(false)
              }}
              sx={{
                textTransform: "uppercase",
              }}
              size="medium"
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    )
  )
}
