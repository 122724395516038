import React, { useCallback, useEffect, useState, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Trans, useTranslation } from "react-i18next"
import { useAppSelector, useAppDispatch } from "@/core/app/hooks"
import { Typography, Button, Box, Stack } from "@mui/material"
import Divider from "@mui/material/Divider"
import { caseManagement, preview, wasmModule } from "@/gluelayer"
import { UButton, UFileBox, UFileProcessingCard, UText } from "@/ui-component"
import {
  uploadFiles,
  downloadFiles,
  requestCloudPresetup,
  testForLoading,
} from "@/core/app/slices/v2/v2apis"
import { resourcesSynchronization } from "@/gluelayer"
import { resetCase } from "@/core/app/slices/clinical/clinicalSlice"
import store from "@/core/app/store"
import {
  fetchFileList,
  getTreatmentInfos,
  uploadCaseZips,
} from "@/core/app/slices/clinical/clinicalThunkApi"
import { LeftPage } from "../left/LeftPage"

export const LRStls = ({ onClose }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation("common")

  const [upperStl, setUpperStl] = useState({ data: null, action: "init" })
  const [lowerStl, setLowerStl] = useState({ data: null, action: "init" })
  const [caseType, setCaseType] = useState<string>(null)

  const { caseId, patientId } = useParams()
  const orgId = store.getState().userService.user.current_orgId

  const UploadSTLBox2 = () => {
    return (
      <Box
        sx={{
          width: "410px",
          height: "100%",
          background: "#fff",
          padding: 2,
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            width: "410px",
            height: "100%",
            background: "#fff",
            padding: 2,
            borderRadius: 1,
          }}
        >
          <>
            <Box id="upper">
              <UText variant={"caption"}>Upper</UText>
              {upperStl.action === "stl" || upperStl.action === "download" ? (
                <UFileProcessingCard
                  fileName={
                    upperStl.action === "stl"
                      ? upperStl.data?.name
                      : "Uploaded Scan"
                  }
                  fileSize={upperStl.data?.size}
                  progressValue={100}
                  progressstatus={"complete"}
                  onRemove={() => {
                    setUpperStl({ data: null, action: "delete" })
                  }}
                ></UFileProcessingCard>
              ) : (
                <UFileBox
                  id={"Upper"}
                  allowedFileExtensions={["stl", "meshinspector"]}
                  allowedfileSize={101200}
                  selectedFile={(file) => {
                    setUpperStl({ data: file[0], action: "stl" })
                  }}
                  fileAcceptType={".stl"}
                  boxSize={{
                    width: "100%",
                    height: 88,
                    padding: "24px 16px",
                  }}
                  fileBoxContent={{
                    flexDirection: "row",
                    gap: 2,
                  }}
                  isRequired={false}
                  messages={{
                    fileNote: t("records.scans.fileNote"),
                    uploadButton: t("records.scans.uploadButton"),
                    uploadButtonSuffix: t("records.scans.uploadButtonSuffix"),
                    invalidfileFormat: t("records.scans.invalidfileFormat"),
                    invalidFileSize: t("records.scans.invalidSTLFileSize"),
                  }}
                  fileLimit={1}
                ></UFileBox>
              )}
            </Box>
            <Box id="lower">
              <UText variant={"caption"}>Lower</UText>
              {lowerStl.action === "stl" || lowerStl.action === "download" ? (
                <UFileProcessingCard
                  fileName={
                    lowerStl.action === "stl"
                      ? lowerStl.data?.name
                      : "Uploaded Scan"
                  }
                  fileSize={lowerStl.data?.size}
                  progressValue={100}
                  progressstatus={"complete"}
                  onRemove={() => {
                    setLowerStl({ data: null, action: "delete" })
                  }}
                ></UFileProcessingCard>
              ) : (
                <UFileBox
                  id={"Lower"}
                  allowedFileExtensions={["stl", "meshinspector"]}
                  allowedfileSize={101200}
                  selectedFile={(file) => {
                    setLowerStl({ data: file[0], action: "stl" })
                  }}
                  fileAcceptType={".stl"}
                  boxSize={{
                    width: "100%",
                    height: 88,
                    padding: "24px 16px",
                  }}
                  fileBoxContent={{
                    flexDirection: "row",
                    gap: 2,
                  }}
                  isRequired={false}
                  messages={{
                    fileNote: t("records.scans.fileNote"),
                    uploadButton: t("records.scans.uploadButton"),
                    uploadButtonSuffix: t("records.scans.uploadButtonSuffix"),
                    invalidfileFormat: t("records.scans.invalidfileFormat"),
                    invalidFileSize: t("records.scans.invalidSTLFileSize"),
                  }}
                  fileLimit={1}
                ></UFileBox>
              )}
            </Box>
          </>
          {showNext()}
        </Box>
      </Box>
    )
  }

  useEffect(() => {
    if (upperStl) caseManagement.saveStlToZip(upperStl.data, true)
  }, [upperStl])
  useEffect(() => {
    if (lowerStl) caseManagement.saveStlToZip(lowerStl.data, false)
  }, [lowerStl])

  const showNext = () => {
    if (upperStl.data && lowerStl.data) {
      if (caseType) {
        return (
          <Box
            id="other"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Divider sx={{ margin: "20px 0px 0px 0px" }}></Divider>
            <UText sxProp={{ margin: "10px 0px" }} variant={"subtitle2"}>
              What type of case is this?
            </UText>
            <UText
              sxProp={{ margin: "0px 10px" }}
              variant={"body2"}
              color={"text.secondary"}
            >
              {caseType}
            </UText>
            <Divider sx={{ margin: "20px 0px 0px 0px" }}></Divider>
            <UText
              sxProp={{ margin: "10px 0px" }}
              color={"text.primary"}
              variant={"subtitle2"}
            >
              How Would you like to plan your case?
            </UText>
            <UButton
              sxProp={{ margin: "10px 0px", color: "#ffffff" }}
              variant={"contained"}
              btnText={"SMARTRX"}
              onClickHandler={async () => {
                // Upload raw data and do presetup
                // preview.saveStlToMtc(upperStl.data, lowerStl.data)
                // fill holes
                // await preview.fillHole()
                const files = await caseManagement.getCaseFiles()

                // download for testing
                // const keys = Object.keys(files)
                // for (let index = 0; index < keys.length; index++) {
                //   const key = keys[index]
                //   const file = files[key]
                //   resourcesSynchronization.download(file.name,file)
                // }

                // upload
                const isOk = await dispatch(
                  uploadFiles({ caseId, zipFiles: files }),
                )
                // const downloadUrl = await dispatch(getS3DownloadUrl({caseId,files:['bulk0']}))
                console.log("上传成功了吗", isOk.payload)

                //invoke presetup
                const hasUpper = upperStl.data ? true : false
                const hasLower = lowerStl.data ? true : false
                console.log("do presetup::", hasUpper, hasLower)
                const isPresetupOK = await dispatch(
                  requestCloudPresetup({
                    orgId,
                    upper: hasUpper,
                    lower: hasLower,
                    patientId,
                    caseId,
                  }),
                )
                console.log("presetup finished?", isPresetupOK)

                // when presetup has finished then reopen case
                await dispatch(resetCase())
                caseManagement.closeCase()
                dispatch(getTreatmentInfos({ patientId }))
                await dispatch(
                  fetchFileList({
                    patientId,
                    caseId,
                  }),
                )
              }}
            ></UButton>
            <UButton
              sxProp={{ color: "#ffffff" }}
              variant={"contained"}
              btnText={"AI PLAN"}
              onClickHandler={async () => {
                // download zip files for test
                // const files = await dispatch(downloadFiles({caseId,files:['raw','bulk0','bulk1','bulk2','bulk10','photo']}))
                // console.log('>>>>>',files)

                // test for loading bar
                dispatch(testForLoading())
              }}
            ></UButton>
            <UText
              sxProp={{ margin: "10px 0px" }}
              color={"text.secondary"}
              variant={"subtitle2"}
            >
              Our AI will work in the background to create your case with either
              choice.final text tbd.
            </UText>
          </Box>
        )
      } else {
        return (
          <Box
            sx={{
              margin: "10px 0px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Divider></Divider>
            <UText sxProp={{ margin: "10px 0px" }} variant={"subtitle2"}>
              What type of case is this?
            </UText>
            <UButton
              sxProp={{ margin: "10px 0px", color: "#ffffff" }}
              variant={"contained"}
              btnText={"ALIGNER"}
              onClickHandler={() => {
                setCaseType("Aligner")
              }}
            ></UButton>
            <UButton
              sxProp={{ color: "#ffffff" }}
              variant={"contained"}
              btnText={"RETAINER"}
              onClickHandler={() => {
                setCaseType("Retainer")
              }}
            ></UButton>
          </Box>
        )
      }
    }
    return <></>
  }
  return (
    <LeftPage
      title="Upload STLs"
      onClose={onClose}
      content={<UploadSTLBox2 />}
    />
  )
}
