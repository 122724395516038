import { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Chip,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import {
  attachmentModule,
  caseManagement,
  orderView,
  stageControl,
  stagingManager,
  toothMovement,
  viewControlInTreatment,
  viewEdit,
  wasmModule,
} from "@/gluelayer"
import { isEmpty } from "lodash"

import nextSvg from "../../../assets/images/clinical/next.svg"
import playSvg from "../../../assets/images/clinical/play.svg"
import previousSvg from "../../../assets/images/clinical/previous.svg"
import stopSvg from "../../../assets/images/clinical/stop.svg"
import orderandprint from "../../../assets/svgs/stagebg.svg"
import SvgBtn from "../../../components/DarkMode/SvgButton/svgbtn"
import WasmCanves from "../../../components/WasmView/wasmCanves"
import Zoom from "../../../components/Zoom/zoom"
import {
  useAppDispatch,
  useAppSelector,
  useDeepEffect,
} from "../../../core/app/hooks"
import { resetCase } from "../../../core/app/slices/clinical/clinicalSlice"
import {
  fetchFileList,
  fetchFileZips,
  fetchRefinementJsonFile,
} from "../../../core/app/slices/clinical/clinicalThunkApi"
import { updateOrderStage } from "../../../core/app/slices/order"
import {
  getQuotation,
  getOrdersByCaseId,
  getStageDetailApi,
} from "../../../core/app/slices/order/orderThunkApi"
import { RootState } from "../../../core/app/store"
import { refinementModule } from "../../../gluelayer/core/modules/refinement"
import { ULoading, UText } from "../../../ui-component"
import { setRefinementNum } from "@/UDTreat/udTreatSlice"
import { getCaseById } from "@/core/app/slices/case/caseThunkApi"
import { getQuoteRequestObj } from "@/modules/Patient/PatientList/util/commonUtil"
const BaseButton = styled(Button)({
  width: 34,
  height: 34,
  minWidth: 34,
  padding: 0,
  color: "rgba(0, 0, 0, 0.87)",
  border: "1px solid",
  borderRadius: 4,
})
let runStageListen
const Stage: FC<{
  alertMessage: AlertColor
  setStageAlertMessage: (alertMessage: AlertColor) => void
}> = ({ alertMessage, setStageAlertMessage }) => {
  const dispatch = useAppDispatch()
  const [zoomValue, setzoomValue] = useState(1)
  const { caseId, patientId } = useParams()
  const [wasmReady, setwasmReady] = useState(false)
  const {
    orderService: {
      stageDetail,
      alignerOrderPayload: { stage },
    },
    clinicalService: { zipNames, zipList, refinementFiles },
  } = useAppSelector((state: RootState) => state)
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const [isUpper, setisUpper] = useState(false)
  const [isLower, setisLower] = useState(false)
  const [showUpper, setShowUpper] = useState(false)
  const [showLower, setShowLower] = useState(false)
  const [isRun, setisRun] = useState(false)

  const [up_current, setup_current] = useState(0)
  const [down_current, setdown_current] = useState(0)

  const [orderStage, setorderStage] = useState<{ up: number[]; low: number[] }>(
    {
      up: [],
      low: [],
    },
  )

  const [printedStage, setprintedStage] = useState<{
    up: number[]
    low: number[]
  }>({
    up: [],
    low: [],
  })

  const [selectedStage, setselectedStage] = useState<{
    up: number[]
    low: number[]
  }>({
    up: [],
    low: [],
  })

  const [firstStage, setfirstStage] = useState<{ up: number; low: number }>({
    up: null,
    low: null,
  })
  const [lastStage, setlastStage] = useState<{ up: number; low: number }>({
    up: null,
    low: null,
  })

  const [upperStage, setupperStage] = useState<
    { stepindex: number; name: string }[]
  >([])
  const [lowerStage, setlowerStage] = useState<
    { stepindex: number; name: string }[]
  >([])
  const [retainers, setretainers] = useState<{ up: number; low: number }>({
    up: 0,
    low: 0,
  })

  const [attachment, setattachment] = useState<{ up: number; low: number }>({
    up: null,
    low: null,
  })

  const [isQuick3, setisQuick3] = useState(false)

  const [isControl, setisControl] = useState(false)

  const { refinementNum } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  // =0:initial case; >0: refinement case
  const [refinements, setrefinements] = useState(null)

  const getArch = (udesign_category) => {


    let caseArch = "both", UpperType = "A", LowerType = "A"

    if (!udesign_category) return caseArch
    UpperType = udesign_category.split("/")[0]

    LowerType = udesign_category.split("/")[1]


    if (UpperType.includes("--")) {
      caseArch = "lower"
    }
    else if (LowerType.includes("--")) {
      caseArch = "upper"
    }

    return { caseArch, UpperType, LowerType }
  }

  const PlayBtnList = (
    <Box
      sx={{
        width: "100%",
        height: 48,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        left: 0,
        bottom: 0,
        backgroundColor: "#979797",
      }}
    >
      <Box sx={{ height: "100%", display: "flex" }}>
        <SvgBtn
          svg={previousSvg}
          clickFun={() => {
            setisRun(false)
            if (isUpper) setup_current(0)
            if (isLower) setdown_current(0)
            if (!isLower && !isUpper) {
              setup_current(0)
              setdown_current(0)
            }
          }}
          isdisable={up_current === 0 && down_current === 0}
          boxSx={{
            width: 48,
            height: 48,
          }}
        ></SvgBtn>
        <SvgBtn
          svg={isRun ? playSvg : stopSvg}
          clickFun={() => {
            setisRun(!isRun)
          }}
          boxSx={{
            width: 48,
            height: 48,
            marginTop: "1px",
          }}
        ></SvgBtn>
        <SvgBtn
          svg={nextSvg}
          clickFun={() => {
            setisRun(false)
            if (isUpper) setup_current(Number(upperStage[upperStage.length - 1]?.name))
            if (isLower) setdown_current(Number(lowerStage[lowerStage.length - 1]?.name))
            if (!isLower && !isUpper) {
              setup_current(Number(upperStage[upperStage.length - 1]?.name))
              setdown_current(Number(lowerStage[lowerStage.length - 1]?.name))
            }
          }}
          isdisable={
            up_current === Number(upperStage[upperStage.length - 1]?.name) || 0 &&
            down_current === Number(lowerStage[lowerStage.length - 1]?.name) || 0
          }
          boxSx={{
            width: 48,
            height: 48,
          }}
        ></SvgBtn>
      </Box>
    </Box>
  )

  useEffect(() => { 
    stageControl.setStageStep(up_current, down_current)
   }, [up_current, down_current])

   const checkStageDifferencesForQuote=(upperStage: { stepindex: number; name: string }[],lowerStage: { stepindex: number; name: string }[])=>{
     if (!caseDetail?.id) return
     const wasmUpper = Number(upperStage[upperStage.length - 1]?.name || 0)
     const wasmLower = Number(lowerStage[lowerStage.length - 1]?.name || 0)
     const plannedUpper = Number(caseDetail?.planned_upper || 0)
     const plannedLower = Number(caseDetail?.planned_lower || 0)
     console.log(
       "wasm:",
       wasmUpper,
       wasmLower,
       "caseStage:",
       plannedUpper,
       plannedLower,
     )
     if (plannedUpper !== wasmUpper || plannedLower !== wasmLower) {
       dispatch(
         getQuotation({
           payload: getQuoteRequestObj(caseDetail, {
             upper: wasmUpper,
             lower: wasmLower,
           }),
           isQuoteUpdate: true,
         }),
       )
     }
   }

  /**
   * stage button click
   * @param upperarch: true/false
   * @param index: current selected tooth id
   * @returns
   */
  const stageHandle = ({ upperarch, index: _index }) => {
    const selected = upperarch ? selectedStage.up : selectedStage.low,
      firstSelectedStage = upperarch ? firstStage.up : firstStage.low,
      lastSelectedStage = upperarch ? lastStage.up : lastStage.low,
      Tattachment = upperarch ? attachment.up : attachment.low,
      index = Number(_index)

    let res = [],
      stage = upperarch ? upperStage : lowerStage

    if (stage.length === 0) {
      stage = [{}, { name: "0", stepindex: 0 }]
    }

    if (isControl) {
      // 按住ctrl键
      // index是已选的stage
      if (selected.indexOf(index) !== -1) {
        res = selected.filter((element) => {
          return element !== index
        })
        upperarch
          ? setselectedStage({
            low: selectedStage.low,
            up: res,
          })
          : setselectedStage({
            up: selectedStage.up,
            low: res,
          })
      } else {
        // index是未选择的stage
        res = [...selected, index]
        upperarch
          ? setselectedStage({
            low: selectedStage.low,
            up: res,
          })
          : setselectedStage({
            up: selectedStage.up,
            low: res,
          })
      }
    } else {
      // 点击第一个stage
      if (!firstSelectedStage) {
        upperarch
          ? setfirstStage({ low: firstStage.low, up: index })
          : setfirstStage({ up: firstStage.up, low: index })
        // index已选过
        if (selected.indexOf(index) !== -1) {
          res = [index]
        } else {
          // index未选过
          res = [...selected, index]
        }

        upperarch
          ? setselectedStage({
            low: selectedStage.low,
            up: res,
          })
          : setselectedStage({
            up: selectedStage.up,
            low: res,
          })
      }
      // 点击第二个stage
      else if (firstSelectedStage && firstSelectedStage !== index) {
        upperarch
          ? setlastStage({ low: lastStage.low, up: index })
          : setlastStage({ up: lastStage.up, low: index })

        let stage1 = firstSelectedStage,
          stage2 = index

        if (firstSelectedStage === 400) {
          res.push(400)
          stage1 = Tattachment === 0 ? 1 : Tattachment
          stage1 = Number(stage[stage1].name)
        }

        if (index === 400) {
          res.push(400)
          stage2 = Tattachment === 0 ? 1 : Tattachment - 1
          stage2 = Number(stage[stage2].name)
        }

        if (firstSelectedStage === 500) {
          res.push(500)
          stage1 = Number(stage[stage.length - 1].name)
        }

        if (index === 500) {
          res.push(500)
          stage2 = Number(stage[stage.length - 1].name)
        }

        if (stage1 > stage2) [stage1, stage2] = [stage2, stage1]

        for (let i = stage1; i < stage2 + 1; i++) {
          if (
            Tattachment !== -1 &&
            i === Number(stage[Tattachment].name) &&
            i !== stage1
          ) {
            res.push(400)
          }
          res.push(i)
        }

        res = Array.from(new Set([...selected, ...res])).filter((item) => {
          return item !== 0
        })

        upperarch
          ? setselectedStage({
            low: selectedStage.low,
            up: res,
          })
          : setselectedStage({
            up: selectedStage.up,
            low: res,
          })

        upperarch
          ? setfirstStage({ low: lastStage.low, up: null })
          : setfirstStage({ up: lastStage.up, low: null })
        upperarch
          ? setlastStage({ low: lastStage.low, up: null })
          : setlastStage({ up: lastStage.up, low: null })
      } else if (firstSelectedStage === index) {
        // 重复点击同一个stage
        const res = selected.filter((item) => {
          return item !== index
        })

        if (upperarch) {
          setselectedStage({
            low: selectedStage.low,
            up: res,
          })
          setfirstStage({ low: null, up: null })
          setlastStage({ low: null, up: null })
        } else {
          setselectedStage({
            up: selectedStage.up,
            low: res,
          })
          setfirstStage({ up: null, low: null })
          setlastStage({ low: null, up: null })
        }
      }
    }
    if (res.indexOf(500) === -1) {
      // unselected R
      upperarch
        ? setretainers({ low: retainers.low, up: 0 })
        : setretainers({ low: 0, up: retainers.up })
    }
  }

  /**
   * find the component by the stage status
   * @param param0
   * @returns
   */
  const stageStatusMap = ({ stageStatus, index, upperarch }) => {
    switch (stageStatus) {
      case "ordered":
        return (
          <BaseButton
            key={index}
            sx={{
              marginRight: 1,
              marginBottom: 1,
              backgroundColor: "#03A9F4",
              borderColor: "rgba(2, 136, 209, 0.50)",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "rgba(2, 136, 209, 0.30)",
                borderColor: "#0288D1",
                boxShadow: "none",
              },
            }}
            variant="contained"
            onClick={() => {
              stageHandle({ index, upperarch })
            }}
          >
            {index === 500 ? "R" : index === 400 ? "T" : index}
          </BaseButton>
        )
      case "printed":
        return (
          <BaseButton
            key={index}
            sx={{
              marginRight: 1,
              marginBottom: 1,
              backgroundColor: "#78909C",
              borderColor: "#546E7A",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.08)",
                boxShadow: "none",
              },
            }}
            variant="contained"
            onClick={() => {
              stageHandle({ index, upperarch })
            }}
          >
            {index === 500 ? "R" : index === 400 ? "T" : index}
          </BaseButton>
        )
      case "order&print":
        return (
          <BaseButton
            key={index}
            sx={{
              marginRight: 1,
              marginBottom: 1,
              backgroundImage: `url(${orderandprint})`,
              borderColor: "rgba(66, 165, 245, 1)",
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
              },
            }}
            variant="contained"
            onClick={() => {
              stageHandle({ index, upperarch })
            }}
          >
            {index === 500 ? "R" : index === 400 ? "T" : index}
          </BaseButton>
        )
      case "selected":
        return (
          <BaseButton
            key={index}
            sx={{
              marginRight: 1,
              marginBottom: 1,
              backgroundColor: "#4CAF50",
              color: "#FFFFFF",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#4CAF50",
                boxShadow: "none",
              },
            }}
            variant={"contained"}
            onClick={() => {
              stageHandle({ index, upperarch })
            }}
          >
            {index === 500 ? "R" : index === 400 ? "T" : index}
          </BaseButton>
        )
      case "disable":
        return (
          <BaseButton
            key={index}
            disabled={true}
            sx={{
              marginRight: 1,
              marginBottom: 1,
              boxShadow: "none",
              backgroundColor: "#FFFFFF",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                boxShadow: "none",
              },
            }}
            variant={"outlined"}
            onClick={() => {
              stageHandle({ index, upperarch })
            }}
          >
            {index === 500 ? "R" : index === 400 ? "T" : index}
          </BaseButton>
        )

      default:
        return (
          <BaseButton
            key={index}
            sx={{
              marginRight: 1,
              marginBottom: 1,
              boxShadow: "none",
              backgroundColor: "#FFFFFF",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                boxShadow: "none",
              },
            }}
            variant={"outlined"}
            onClick={() => {
              stageHandle({ index, upperarch })
            }}
          >
            {index === 500 ? "R" : index === 400 ? "T" : index}
          </BaseButton>
        )
    }
  }

  /**
   * 
   * @param index  number
   * @param upperarch boolean
   * @returns 
   */
  const getStepStatus = (index, upperarch) => {
    const ordered = upperarch ? orderStage.up : orderStage.low,
      printed = upperarch ? printedStage.up : printedStage.low,
      selected = upperarch ? selectedStage.up : selectedStage.low

    let stageStatus = "default"
    // ordered
    if (ordered.indexOf(Number(index)) !== -1) {
      stageStatus = "ordered"
    }
    // printed
    if (printed.indexOf(Number(index)) !== -1) {
      stageStatus !== "ordered"
        ? (stageStatus = "printed")
        : (stageStatus = "order&print")
    }

    if (!isEmpty(selected) && selected.indexOf(Number(index)) !== -1) {
      stageStatus = "selected"
    }
    if (isQuick3 && [0, 1, 2].indexOf(index) !== -1) {
      stageStatus = "disable"
    }
    return stageStatus
  }

  /**
   * save the select stage to redux
   */
  const saveDataToMemery = ({ selectedStage, retainers: r }) => {
    const templates = { up: null, low: null },
      retainers = { ...r }
    const { firstStage } = caseManagement.getAttachmentIPRReport()
    const upper = selectedStage.up.filter((item) => {
      if (item === 400) {
        templates.up = Math.max(
          upperStage.length === 0
            ? Math.max(stagingManager.wasmStageData.upStartStep, 0)
            : Number(upperStage[attachment.up].name) === 0 
              ? stagingManager.wasmStageData.upStartStep
              : Number(upperStage[attachment.up].name)
                ? Number(upperStage[attachment.up].name) - 1
                : upperStage[attachment.up - 1] && !isNaN(Number(upperStage[attachment.up - 1].name))
                  ? Number(upperStage[attachment.up - 1].name)
                  : Number(upperStage[attachment.up + 1].name) - 1
          , 0)
      }
      if (item === 500) {
        retainers.up = r.up + 1
      }
      return item !== 500 && item !== 400
    })
    const lower = selectedStage.low.filter((item) => {
      if (item === 400) {
        templates.low = Math.max(
          lowerStage.length === 0
            ? Math.max(stagingManager.wasmStageData.lowStartStep, 0)
            : Number(lowerStage[attachment.low].name) === 0 
              ? stagingManager.wasmStageData.lowStartStep
              : Number(lowerStage[attachment.low].name)
                ? Number(lowerStage[attachment.low].name) - 1
                : lowerStage[attachment.low - 1] && !isNaN(Number(lowerStage[attachment.low - 1].name))
                  ? Number(lowerStage[attachment.low - 1].name)
                  : Number(lowerStage[attachment.low + 1].name) - 1
          , 0)
      }
      if (item === 500) {
        retainers.low = r.low + 1
      }
      return item !== 500 && item !== 400
    })
    dispatch(
      updateOrderStage({
        aligners: { up: upper, low: lower },
        retainers,
        templates,
      }),
    )
  }

  const stageComponent = (
    upperarch = true,
    upperStage,
    lowerStage,
    retainers,
    selectedStage,
    attachment,
  ) => {
    const stageListComponent = [],
      stageNumber = upperarch ? upperStage.length : lowerStage.length,
      stage = upperarch ? upperStage : lowerStage,
      retainer = upperarch ? retainers.up : retainers.low,
      selected = upperarch ? selectedStage.up : selectedStage.low,
      Tattachment = upperarch ? attachment.up : attachment.low,
      isRetainerArch = upperarch ? getArch(caseDetail.udesign_category).UpperType === "R" : getArch(caseDetail.udesign_category).LowerType === "R"
    // let Crefinement = ""
    let index = 1
    // const [{ name: name0 } = { name: "" }] = stage

    // if (refinements > 0) {
    //   // 是否是 refinement case
    //   Crefinement = name0
    // }
    // attachement是否在0 stage
    if (Tattachment === 0) {
      stageListComponent.push(
        stageStatusMap({
          stageStatus: getStepStatus(400, upperarch),
          index: 400,
          upperarch,
        }),
      )
    }
    // 非 retainer arch
    if (!isRetainerArch) {
      for (index; index < stageNumber; index++) {
        const { name } = stage[index]
        // attachement stage前+T
        if (Tattachment > 0 && Number(stage[Tattachment]?.name) === Number(name)) {
          stageListComponent.push(
            stageStatusMap({
              stageStatus: getStepStatus(400, upperarch),
              index: 400,
              upperarch,
            }),
          )
        }
        stageListComponent.push(
          stageStatusMap({
            stageStatus: getStepStatus(Number(name), upperarch),
            index: name,
            upperarch,
          }),
        )
      }
    }
    // 如果是refinement case，首位+C
    // if (Crefinement) {
    //   stageListComponent.unshift(
    //     <Box
    //       id="tooltip"
    //       height={"34px"}
    //       width={"34px"}
    //       lineHeight={"34px"}
    //       textAlign={"center"}
    //       marginRight={"8px"}
    //     >
    //       <Tooltip
    //         title={"Course refinement" + Crefinement.slice(1)}
    //         placement="top"
    //       >
    //         <Box
    //           component={"span"}
    //           sx={{
    //             backgroundColor: "rgba(243, 229, 245, 1)",
    //             color: "rgba(156, 39, 176, 1)",
    //             borderRadius: 2,
    //             fontSize: 10,
    //             padding: "4px 8px",
    //           }}
    //         >
    //           {Crefinement}
    //         </Box>
    //       </Tooltip>
    //     </Box>,
    //   )
    // }
    // 末尾+R
    stageListComponent.push(
      stageStatusMap({
        stageStatus: getStepStatus(500, upperarch),
        index: 500,
        upperarch,
      }),
    )

    return (
      <Box sx={{ marginTop: "16px", marginBottom: "32px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 38,
            lineHeight: 38,
            width: "100%",
          }}
        >
          <Box display={"flex"} alignItems={"center"}>
            <UText variant="subtitle2">
              {upperarch ? "Upper arch" : "Lower arch"}
            </UText>
          </Box>

          <Box display={"flex"}>
            {selected.length > 0 && (
              <Chip
                label="Clear All"
                sx={{ marginRight: 1 }}
                onDelete={() => {
                  if (upperarch) {
                    setselectedStage({ low: selectedStage.low, up: [] })
                    setretainers({ low: retainers.low, up: 0 })
                    setfirstStage({ up: null, low: firstStage.low })
                  } else {
                    setselectedStage({ up: selectedStage.up, low: [] })
                    setretainers({ low: 0, up: retainers.up })
                    setfirstStage({ low: null, up: firstStage.up })
                  }
                }}
              />
            )}
            <ToggleButtonGroup size="small">
              <ToggleButton
                size="small"
                value={"SPRINT 24"}
                disabled={stageNumber < 25}
                onClick={() => {
                  const res = []
                  stage.forEach((item) => {
                    if (item.stepindex < 25) {
                      if (item.stepindex !== 0) res.push(Number(item.name))
                      if (stage[Tattachment]?.name === item.name) res.push(400)
                    }
                  })
                  if (upperarch) {
                    setselectedStage({ low: selectedStage.low, up: res })
                  } else {
                    setselectedStage({ up: selectedStage.up, low: res })
                  }
                }}
              >
                SPRINT 24
              </ToggleButton>
              <ToggleButton
                size="small"
                value={"SELECT ALL"}
                onClick={() => {
                  const res = [],
                    filterStage = upperarch
                      ? [...printedStage.up, ...orderStage.up]
                      : [...printedStage.low, ...orderStage.low]
                  stage.forEach((item) => {
                    if (
                      item.name !== "0" &&
                      !filterStage.includes(item.stepindex)
                    )
                      res.push(Number(item.name))
                  })
                  if (Tattachment >= 0 && !filterStage.includes(400)) {
                    res.unshift(400)
                  }
                  if (upperarch) {
                    setselectedStage({ low: selectedStage.low, up: res })
                  } else {
                    setselectedStage({ up: selectedStage.up, low: res })
                  }
                }}
              >
                SELECT ALL ALIGNERS
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexWrap={"wrap"}
          sx={{ marginTop: "16px", padding: "0 !important" }}
        >
          {stageListComponent}
        </Box>
        <Box>
          <UText variant="caption">Add extra retainers</UText>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: "1px solid rgba(0, 0, 0, 0.56)",
            width: "fit-content",
            borderRadius: "4px",
          }}
        >
          <Button
            sx={{
              width: 34,
              height: 34,
              minWidth: 34,
              color: "rgba(0, 0, 0, 0.56)",
            }}
            onClick={() => {
              if (upperarch) setretainers({ ...retainers, up: retainer - 1 })
              else setretainers({ ...retainers, low: retainer - 1 })
            }}
            disabled={retainer === 0 || selected.indexOf(500) === -1}
          >
            -
          </Button>
          <Box
            sx={{
              fontSize: 14,
              fontWeight: 500,
              width: 34,
              height: 34,
              textAlign: "center",
              lineHeight: "34px",
              color:
                retainer !== 0 ? "rgba(76, 175, 80, 1)" : "rgba(0, 0, 0, 0.56)",
            }}
          >
            {retainer}
          </Box>
          <Button
            sx={{
              width: 34,
              height: 34,
              minWidth: 34,
              color: "rgba(0, 0, 0, 0.56)",
            }}
            onClick={() => {
              if (upperarch) setretainers({ ...retainers, up: retainer + 1 })
              else setretainers({ ...retainers, low: retainer + 1 })
            }}
            disabled={selected.indexOf(500) === -1}
          >
            +
          </Button>
        </Box>
      </Box>
    )
  }

  const callbackAfterLoadDone = () => {
    viewControlInTreatment.setViewType("front")
    viewControlInTreatment.showArchMode("all")
    viewControlInTreatment.setZoomCallbackInTreatment(setzoomValue)
    toothMovement.setIsEnableMoveTooth(false)
    viewEdit.switchViewToEdit(false)
    orderView.saveElasticJsonFile([]);
    setwasmReady(true)
    // caseManagement.setBackgroundColor(36 / 255, 46 / 255, 51 / 255)
    caseManagement.setBackgroundPic("backgroundvtk.png")
    const {
      firstStage: { upStageIndex, lowerStageIndex },
    } = caseManagement.getAttachmentIPRReport()
    setattachment({
      up: upStageIndex,
      low: lowerStageIndex,
    })
  }

  useEffect(() => {
    if (!isRun) {
      clearInterval(runStageListen)
      return
    }
    let up_current = Number(upperStage[0]?.name || 0),
      down_current = Number(lowerStage[0]?.name || 0)
    runStageListen = setInterval(() => {
      if (up_current < Number(upperStage[upperStage.length - 1]?.name || 0)) {
        up_current++
      }
      if (down_current < Number(lowerStage[lowerStage.length - 1]?.name || 0)) {
        down_current++
      }
      setup_current(up_current)
      setdown_current(down_current)
      if (
        up_current === Number(upperStage[upperStage.length - 1]?.name || 0) &&
        down_current === Number(lowerStage[lowerStage.length - 1]?.name || 0)
      ) {
        clearInterval(runStageListen)
        setisRun(false)
      }
    }, 800)
  }, [isRun])

  useDeepEffect(() => {
    if (!isEmpty(selectedStage.up) || !isEmpty(selectedStage.low)) return
    const {
      aligners: { up, low },
      retainers: { up: r_up, low: r_low },
      templates: { up: t_up, low: t_low },
    } = stage
    const selected = { up: [...up], low: [...low] }
    if (r_up !== 0) {
      selected.up.push(500)
    }
    if (r_low !== 0) {
      selected.low.push(500)
    }
    if (t_up !== null) {
      selected.up.push(400)
    }
    if (t_low !== null) {
      selected.low.push(400)
    }
    setselectedStage(selected)
    setretainers({
      up: r_up === 0 ? r_up : r_up - 1,
      low: r_low === 0 ? r_low : r_low - 1,
    })
  }, [stage])

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      setisControl(e.key === "Control")
    })
    window.addEventListener("keyup", (e) => {
      setisControl(false)
    })
    dispatch(getStageDetailApi({ caseId, patientId }))
    dispatch(getCaseById({ caseId, patientId }))
    dispatch(getOrdersByCaseId(Number(caseId)))


    if (isEmpty(zipNames)) {
      dispatch(
        fetchFileList({
          patientId,
          caseId,
          fileNames: [
            "bulk0",
            "bulk1",
            "bulk2",
            "bulk10",
            "storage.version.json",
          ],
        }),
      )
    }


    return () => {
      clearInterval(runStageListen)
      // clear case data
      dispatch(resetCase())
    }
  }, [])

  useDeepEffect(() => {
    if (!wasmReady) return
    if (
      isEmpty(selectedStage.up) &&
      isEmpty(selectedStage.low) &&
      isEmpty(retainers.up) &&
      isEmpty(retainers.low)
    ) {
      setStageAlertMessage("info")
      saveDataToMemery({ selectedStage, retainers })
      return
    }
    saveDataToMemery({ selectedStage, retainers })
  }, [selectedStage.up, selectedStage.low, retainers.up, retainers.low])

  useEffect(() => {
    if (!wasmReady) return
    // console.log('????????11111');
    viewControlInTreatment.zoomWithValueInTreatment(zoomValue)
  }, [zoomValue, wasmReady])

  /**
   *  解析stageDetail，赋予stage状态
   */
  useEffect(() => {
    if (isEmpty(stageDetail) || !wasmReady) return
    let currentStage = []

    let refinementCount = 0
    if (refinementNum === 0) {
      if (!refinementModule) return
      const refinementHistoryList = refinementModule.getRefinementDateInfo()
      refinementCount = Object.keys(refinementHistoryList).length
      dispatch(setRefinementNum(refinementCount))
      setrefinements(refinementCount - 1)
    } else {
      refinementCount = refinementNum
      setrefinements(refinementNum - 1)
    }
    const { jointUpKeypoints, jointLowerKeypoints } = stagingManager.wasmStageData,
      up_res = [],
      low_res = []
    if (refinementCount === 0) return
    const refines = refinementCount - 1
    if (refines > 0) {
      const name = "C" + refines
      currentStage = stageDetail[name] || []
    }
    jointUpKeypoints.forEach((item) => {
      if (item.name.includes("R")) 
        up_res.push({ ...item, name: '0' })
      else
        up_res.push(item)
    })
    jointLowerKeypoints.forEach((item) => {
      if (item.name.includes("R"))
        low_res.push({ ...item, name: '0' })
      else
      low_res.push(item)
    })
    if (refines === 0) {
      currentStage = stageDetail?.initial || []
    }
    setupperStage(up_res)
    setlowerStage(low_res)
    checkStageDifferencesForQuote(up_res,low_res)
    setup_current(Number(up_res[up_res.length - 1]?.name) || 0)
    setdown_current(Number(low_res[low_res.length - 1]?.name) || 0)
    let upper_print = [],
      upper_order = [],
      lower_print = [],
      lower_order = []

    for (let index = 0; index < currentStage.length; index++) {
      const { arch_type, stage, status } = currentStage[index]
      if (arch_type === 0) {
        if (status === "IN_OFFICE") {
          upper_print = [...upper_print, stage]
          if (stage > 500) {
            upper_print.push(500)
          }
          else if (stage >= 400) {
            upper_print.push(400)
          }
        }
        if (status === "PREVIOUSLY_ORDERED") {
          upper_order = [...upper_order, stage]
          if (stage > 500) {
            upper_order.push(500)
          }
          else if (stage >= 400) {
            upper_order.push(400)
          }
        }
      } else {
        if (status === "IN_OFFICE") {
          lower_print = [...lower_print, stage]
          if (stage > 500) {
            lower_print.push(500)
          }
          else if (stage >= 400) {
            lower_print.push(400)
          }
        }
        if (status === "PREVIOUSLY_ORDERED") {
          lower_order = [...lower_order, stage]
          if (stage > 500) {
            lower_order.push(500)
          }
          else if (stage >= 400) {
            lower_order.push(400)
          }
        }
      }
    }
    setprintedStage({ up: upper_print, low: lower_print })
    setorderStage({ up: upper_order, low: lower_order })


  }, [stageDetail, wasmReady])




  useEffect(() => {

    if (
      (Object.keys(zipList).length == 5 && refinementFiles.length == 0) ||
      (refinementFiles.length > 0 && Object.keys(zipList).length > 5)
    ) {

      caseManagement.openCaseOrSwitchCanvas(
        document.getElementById("canvas") as HTMLCanvasElement,
        zipList as any,
        () => {
          callbackAfterLoadDone()
        },
      )

    }



  }, [zipList])



  useEffect(() => {
    if (isEmpty(zipList) && !isEmpty(zipNames)) {
      dispatch(
        fetchFileZips({
          orgId: "",
          patientId,
          caseId,
          zipNames,
        }),
      )
    }
  }, [zipNames])

  useEffect(() => {
    if (!isEmpty(refinementFiles)) {
      dispatch(
        fetchRefinementJsonFile({
          orgId: "",
          patientId,
          caseId,
          refinementFiles,
        }),
      )
    }
  }, [refinementFiles])

  useEffect(() => {
    if (caseDetail && caseDetail.udesign_json) {
      const udesign_json = JSON.parse(caseDetail.udesign_json)
      const udesignCategory = caseDetail?.udesign_category
      const arches = udesignCategory && udesignCategory.split("/")
      if (
        udesign_json.UpperType === "A" ||
        udesign_json.UpperType === "R" ||
        (arches && arches[0].includes("R")) ||
        (arches && arches[0].includes("A")) ||
        udesign_json.upperUploaded
      ) {
        setShowUpper(true)
      }
      if (
        udesign_json.LowerType === "A" ||
        udesign_json.LowerType === "R" ||
        (arches && arches[1].includes("R")) ||
        (arches && arches[1].includes("A")) ||
        udesign_json.lowerUploaded
      ) {
        setShowLower(true)
      }
    }
  }, [caseDetail])

  useEffect(() => {
    if (!wasmReady) return
    viewControlInTreatment.showArchMode(
      isUpper && !isLower ? "up" : !isUpper && isLower ? "low" : "all",
    )
  }, [isUpper, isLower, wasmReady])
  return (
    <>
      <ULoading isLoading={!wasmReady} />
      <Box
        id="tooth-view"
        sx={{
          height: "auto",
          width: "100%",
          padding: "14px 135px",
          background: "#FFFFFF",
          borderRadius: "16px",
          mb: 6,
          mx: 3,
        }}
      >
        <Box
          sx={{
            position: "relative",
            height: 380,
            background: "#455A64",
            margin: "0px 141px",
          }}
        >
          <Zoom
            setisUpper={setisUpper}
            setzoomValue={setzoomValue}
            zoomValue={zoomValue}
            isUpper={isUpper}
            isLower={isLower}
            setisLower={setisLower}
          ></Zoom>
          <WasmCanves
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              left: "0",
              top: "0",
              backgroundColor: "#979797",
            }}
          />
          {PlayBtnList}
        </Box>
        <Alert
          sx={{ marginTop: 4 }}
          severity={alertMessage}
          variant="outlined"
          title={
            alertMessage === "error"
              ? "Select stages to continue."
              : alertMessage === "warning"
                ? "Extra charges needed."
                : ""
          }
        >
          {alertMessage === "info"
            ? "uLab recommends ordering in sprint (batches) of up to 24 stages and monitor treatment progress. Ordering in sprints minimizes product waste, assists with treatment efficiencies, and reduces in-office storage needs."
            : alertMessage === "error"
              ? "You need to select at least one stage to continue order."
              : alertMessage === "warning" &&
              "Your stage selection exceeds the bundle allotment. Additional stages will be added as a la carte items at checkout."}
        </Alert>
        <Box sx={{ padding: "16px 0" }}>
          <UText variant="h6">Select stages to be ordered:</UText>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 10,
              float: "right",
              height: "100%",
              color: "rgba(0, 0, 0, 0.87)",
              fontWeight: 500,
            }}
          >
            <Box
              sx={{
                height: 18,
                width: 18,
                backgroundColor: "#78909C",
                borderRadius: 0.5,
                margin: 1,
              }}
            ></Box>
            Printed in office
            <Box
              sx={{
                height: 18,
                width: 18,
                backgroundColor: "#03A9F4",
                borderRadius: 0.5,
                margin: 1,
              }}
            ></Box>
            Previously ordered
            <Box
              sx={{
                height: 18,
                width: 18,
                backgroundColor: "#4CAF50",
                borderRadius: 0.5,
                margin: 1,
              }}
            ></Box>
            Current order
          </Box>
        </Box>
        <Box>
          {getArch(caseDetail.udesign_category).caseArch !== "lower" && showUpper && (
            <Box>
              {stageComponent(
                true,
                upperStage,
                lowerStage,
                retainers,
                selectedStage,
                attachment,
              )}
            </Box>
          )}
          {getArch(caseDetail.udesign_category).caseArch !== "upper" && showLower && (
            <Box>
              {stageComponent(
                false,
                upperStage,
                lowerStage,
                retainers,
                selectedStage,
                attachment,
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default Stage
