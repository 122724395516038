import { useState, useEffect, useRef } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material"
import JSZip from "jszip"
import { RootState } from "@/core/app/store"
import { useAppSelector, useAppDispatch } from "@/core/app/hooks"

import {
  caseManagement,
  wasmModule,
  stageControl,
  attachmentModule,
  resourcesSynchronization,
  stagingManager,
  EArchType,
} from "@/gluelayer"
import {
  fetchFileList,
  fetchFileZips,
  fetchRefinementJsonFile,
  fetchZipsFromCacheOrRemote,
  openCase,
} from "@/core/app/slices/clinical/clinicalThunkApi"
import { preview } from "@/gluelayer"
import {
  resetCase,
  setIsShowProgress,
  setProgressMsg,
  setRefinementReady,
} from "@/core/app/slices/clinical/clinicalSlice"
import {
  setCurCaseMode,
  setCurLeftPage,
  setHasUploadSTLs,
  setIsCasePreview,
  setReportData,
  setStageDataLen,
  setSwitchViewEdit,
  setStageShowType,
  setOpenHistorySplitView,
} from "./udTreatSlice"
import { fetchClinicalSettings } from "@/core/app/slices/user/userApis"
import { setPlanList } from "./udTreatSlice"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import { setSingleArchBool } from "./udTreatSlice"


async function checkIsCaseFull(zipList: Record<string, Blob>){
  console.log("?????",zipList);
  const blob2 = zipList["bulk2.zip"]
  console.log("XY: Blob2 check - Exists:", !!blob2, "Size:", blob2?.size)

  if (blob2) {
    console.log("XY: Processing bulk2 file...")
    const arrayBuffer = await blob2.arrayBuffer()
    const zip = new JSZip()
    const loadedZip = await zip.loadAsync(arrayBuffer)
    console.log("XY: ZIP file loaded for bulk2")

    // 读取 ModuleControl.data 文件
    const targetFileName = "Setting Data2/ModuleControl.data"
    const entry = loadedZip.files[targetFileName]

    if (entry && !entry.dir) {
      const data = await entry.async("string")
      const lines = data.split("\n")

      let newVersionFile = false
      let numberOfProcessModule = 0
      let closedModuleCount = 0

      // 检查文件内容
      if (lines.length > 2 && /^\d+$/.test(lines[2].trim())) {
        newVersionFile = true
        numberOfProcessModule = parseInt(lines[2].trim())
      } else {
        numberOfProcessModule = parseInt(lines[1].trim())
      }

      if (numberOfProcessModule === 0) {
        return false // 没有模块
      }

      console.log("XY: numberOfProcessModule is ", numberOfProcessModule)

      // 处理模块信息
      for (let i = 0; i < numberOfProcessModule; i++) {
        // 读取状态行
        const moduleStatusLine = lines[newVersionFile ? 3 + i * 5 : 2 + i * 4]
        console.log(`XY: Module ${i} - Status: ${moduleStatusLine}`)

        // 读取名称行
        const moduleNameLine = lines[newVersionFile ? 4 + i * 5 : 3 + i * 4]
        console.log(`XY: Module ${i} - Name: ${moduleNameLine}`)

        // 读取下一个行（状态值）
        const nextLine = lines[newVersionFile ? 5 + i * 5 : 4 + i * 4]
        console.log(`XY Next Line after Module ${i}: "${nextLine}"`)

        // 解析状态值
        const statusValue = parseInt(nextLine.trim())

        if (statusValue > 0) {
          closedModuleCount++
        }
        console.log(
          "XY: After closedModuleCount is ",
          closedModuleCount,
          " i is ",
          i,
        )

        // 检查是否所有模块都关闭
        if (closedModuleCount === numberOfProcessModule) {
          console.log("XY: IS goto setup")
          return true // 所有模块都关闭
        }

        // 读取额外的行（如果是新版本文件）
        if (newVersionFile) {
          const extraLine1 = lines[6 + i * 5]
          const extraLine2 = lines[7 + i * 5]
          const extraLine3 = lines[8 + i * 5]
          console.log(`XY: Final Line 1 for Module ${i}: "${extraLine1}"`)
          console.log(`XY: Final Line 2 for Module ${i}: "${extraLine2}"`)
          // 处理额外行的逻辑（如果需要）
        } else {
          const extraLine3 = lines[5 + i * 4]
          // 处理额外行的逻辑（如果需要）
        }
      }
    } else {
      console.log(`XY: ${targetFileName} not found in the ZIP file.`)
    }
  } else {
    console.log("XY: bulk2 does not exist in zipList.")
  }
  return false
}


export const UDOpenCase = ({ open, onClose, switchEditCallBack }) => {
  const theme = useTheme()

  const { caseId, patientId } = useParams()
  const dispatch = useAppDispatch()
  const {
    fileListReady,
    isLoading,
    zipList,
    zipNames,
    refinementFiles,
    isShowProgress,
    ProgressMsg,
  } = useAppSelector((state: RootState) => state.clinicalService)

  const { curCaseMode, isCasePreview } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )

  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)

  const [alert, setAlert] = useState(false)
  const isLoadingCase = useRef(false)
  const navigate = useMyNavigation()
  const location = useLocation()
  const currentOpenCaseZips = useRef(null)

  // Get Treatment Infos and zip file names
  // useEffect(() => {
  //   console.log("getTreatmentInfos ::")
  //   dispatch(setCurCaseMode("WeDesign"))
  //   dispatch(setCurLeftPage(""))
  //   stagingManager.clearWasmStageData()
  //   dispatch(setStageShowType(""))
  //   dispatch(setOpenHistorySplitView(false))
  //   caseManagement.closeCase()
  //   dispatch(resetCase())
  //   dispatch(setSwitchViewEdit(false))
  //   dispatch(setIsCasePreview(true)) // default is preview only
  //   setAlert(false)

  //   // Get zip file names
  //   dispatch(
  //     fetchFileList({
  //       patientId,
  //       caseId,
  //     }),
  //   )
  //   dispatch(fetchClinicalSettings())

  //   dispatch(setRefinementReady(false))
  //   // dispatch(setIsShowProgress(true))
  //   // dispatch(setProgressMsg("Start download case..."))

  //   return () => {
  //     dispatch(resetCase())
  //     dispatch(setIsShowProgress(false))
  //   }
  // }, [])

  const isZipFilesReady = () => {
    return zipList && Object.keys(zipList).length > 1
  }

  useEffect(()=>{
    return ()=>{
      dispatch(resetCase());
    }
  },[])


 useEffect(()=>{
  if(!isZipFilesReady()){
    getZipFiles();
  }
 },[zipList])


  const getZipFiles = async()=>{
    await dispatch(setIsShowProgress(true))
    const ret = await dispatch(fetchZipsFromCacheOrRemote({caseId,patientId}))
    if (ret.error) {
      console.warn("openCase...error...",ret.error)
      return
    }
  }


  const loadCase = async ()=>{
    
    if (!caseDetail.udesign_json ) {
      return;
    }
    isLoadingCase.current=true

    await dispatch(setIsShowProgress(true))
    

    const openCasePreview = (zips: Record<string, Blob>,callback:()=>void) => {
      caseManagement.closeCase()
      preview
        .initScanView(
          document.getElementById("canvas") as HTMLCanvasElement,
          zips,
        )
        .then(async () => {
          const res = preview.drawScanMtc([0.25, 4])
  
          const originArchTypeData = resourcesSynchronization.getFileDirectly(
            "ArchType.json",
            "Setting Data2",
            "utf8",
          )
          console.log(
            "🚀 ~ openCasePreview ~ originArchTypeData:",
            originArchTypeData,
          )
          await dispatch(
            setHasUploadSTLs([
              res.get("arch_o_u.mtc") !== null,
              res.get("arch_o_l.mtc") !== null,
            ]),
          )

          await dispatch(setIsShowProgress(false))

          callback()
        })
      
    }
  
    const openCaseFull = async (zipList: Record<string, Blob>,callback:()=>void) => {
      caseManagement.closeCase()

      await caseManagement.openCaseWithPromise(document.getElementById("canvas") as HTMLCanvasElement, zipList)

      
      setPreparation(true)
      caseManagement.setBackgroundPic("backgroundvtk.png") //背景图只需要将图片名字传入，图片存储在resource/Pictures/下
      dispatch(setHasUploadSTLs([true, true]))
      // get txplan data
      const txList = caseManagement.getTreatmentPlanList()
      dispatch(setPlanList(txList))
      // get stage len
      const { jointUpKeypoints, jointLowerKeypoints } =
        stageControl.getWasmStageData()
      dispatch(
        setStageDataLen({
          up: jointUpKeypoints.length,
          low: jointLowerKeypoints.length,
        }),
      )
      // get reportData
      getReportFunc()
      // report update callback function
      caseManagement.setOnReportUpdateCB((data) => {
        const { attachment, toothlist, ipr } = data

        const { lowlist, uplist } = toothlist
        // setAttachmentObj(obj)
        const list = lowlist.concat(uplist)
        dispatch(
          setReportData({
            attachment,
            toothlist: list,
            iprData: ipr,
          }),
        )
      })
      // get single arch bool
      const hasUpper = wasmModule.getArchModel(EArchType.UpArch)
      const hasLower = wasmModule.getArchModel(EArchType.LowArch)
      dispatch(setSingleArchBool(hasUpper == null || hasLower == null))
      callback();
    }


    console.log("openCase.....start...",caseDetail,zipList)

    // check if the case is complete?
    if ((Object.keys(zipList).length >= 6 )) {
      const zips = zipList as Record<string, Blob>
      const checkFullCase = checkIsCaseFull(zips)
  
      checkFullCase.then(async (isFullCase) => {
        console.log("🚀 ~ checkFullCase.then ~ isFullCase:", isFullCase)
        const caseDisposition = caseDetail.caseDisposition
        const udesign_json = JSON.parse(caseDetail.udesign_json)
        if (
          caseDisposition === "FROM_UDESIGN" &&
          !isFullCase &&
          udesign_json.needPresetup === undefined
        ) {
          setAlert(true)
          console.log("openCase..... out1",zipList);
          return;
        }

        dispatch(setIsCasePreview(!isFullCase))
  
        //SUBMITTED_TO_UASSIST  TREATMENT_IN_PROGRESS
        if (isFullCase) {
          await dispatch(setProgressMsg("Open full case ..."))
          setTimeout(()=>{
            openCaseFull(zips,()=>{
              isLoadingCase.current=false
            })
          },100);
          //openCaseFull(zips)
        } else {
          await dispatch(setProgressMsg("Open preview case ..."))
          openCasePreview(zips,()=>{
            isLoadingCase.current=false
          })
        }
      })
    }
  }

  useEffect(() => {
    if (!isZipFilesReady() || !caseDetail.udesign_json) {
      return;
    }

    if(isLoadingCase.current){
      return;
    }
    if (currentOpenCaseZips.current !== zipList) {
      loadCase()
      currentOpenCaseZips.current = zipList;
    }
    
  }, [zipList,caseDetail])

  useEffect(() => {
    let bgColor =
      theme.palette.mode === "dark"
        ? { r: 128 / 255, g: 128 / 255, b: 128 / 255 }
        : { r: 66 / 255, g: 95 / 255, b: 107 / 255 }
    if (wasmModule.isInit)
      // caseManagement.SetBackgroundGradient("F8F8FF", "#DCDCDC")
      // caseManagement.setBackgroundColor(bgColor.r, bgColor.g, bgColor.b)
      caseManagement.setBackgroundPic("backgroundvtk.png") //背景图只需要将图片名字传入，图片存储在resource/Pictures/下
  }, [theme])

  // useEffect(() => {
  //   if (isLoading === "failed" && isShowProgress) {
  //     console.log("Something wrong for clincal service...")
  //     dispatch(setProgressMsg("Something is wrong while loading case"))
  //     dispatch(setIsShowProgress(false))
  //     dispatch(setCurCaseMode("NewCase"))
  //   }
  // }, [isLoading])

  // const checkIsCaseFull = async (zipList: Record<string, Blob>) => {
  //   console.log("?????",zipList);
  //   const blob2 = zipList["bulk2.zip"]
  //   console.log("XY: Blob2 check - Exists:", !!blob2, "Size:", blob2?.size)

  //   if (blob2) {
  //     console.log("XY: Processing bulk2 file...")
  //     const arrayBuffer = await blob2.arrayBuffer()
  //     const zip = new JSZip()
  //     const loadedZip = await zip.loadAsync(arrayBuffer)
  //     console.log("XY: ZIP file loaded for bulk2")

  //     // 读取 ModuleControl.data 文件
  //     const targetFileName = "Setting Data2/ModuleControl.data"
  //     const entry = loadedZip.files[targetFileName]

  //     if (entry && !entry.dir) {
  //       const data = await entry.async("string")
  //       const lines = data.split("\n")

  //       let newVersionFile = false
  //       let numberOfProcessModule = 0
  //       let closedModuleCount = 0

  //       // 检查文件内容
  //       if (lines.length > 2 && /^\d+$/.test(lines[2].trim())) {
  //         newVersionFile = true
  //         numberOfProcessModule = parseInt(lines[2].trim())
  //       } else {
  //         numberOfProcessModule = parseInt(lines[1].trim())
  //       }

  //       if (numberOfProcessModule === 0) {
  //         return false // 没有模块
  //       }

  //       console.log("XY: numberOfProcessModule is ", numberOfProcessModule)

  //       // 处理模块信息
  //       for (let i = 0; i < numberOfProcessModule; i++) {
  //         // 读取状态行
  //         const moduleStatusLine = lines[newVersionFile ? 3 + i * 5 : 2 + i * 4]
  //         console.log(`XY: Module ${i} - Status: ${moduleStatusLine}`)

  //         // 读取名称行
  //         const moduleNameLine = lines[newVersionFile ? 4 + i * 5 : 3 + i * 4]
  //         console.log(`XY: Module ${i} - Name: ${moduleNameLine}`)

  //         // 读取下一个行（状态值）
  //         const nextLine = lines[newVersionFile ? 5 + i * 5 : 4 + i * 4]
  //         console.log(`XY Next Line after Module ${i}: "${nextLine}"`)

  //         // 解析状态值
  //         const statusValue = parseInt(nextLine.trim())

  //         if (statusValue > 0) {
  //           closedModuleCount++
  //         }
  //         console.log(
  //           "XY: After closedModuleCount is ",
  //           closedModuleCount,
  //           " i is ",
  //           i,
  //         )

  //         // 检查是否所有模块都关闭
  //         if (closedModuleCount === numberOfProcessModule) {
  //           console.log("XY: IS goto setup")
  //           return true // 所有模块都关闭
  //         }

  //         // 读取额外的行（如果是新版本文件）
  //         if (newVersionFile) {
  //           const extraLine1 = lines[6 + i * 5]
  //           const extraLine2 = lines[7 + i * 5]
  //           const extraLine3 = lines[8 + i * 5]
  //           console.log(`XY: Final Line 1 for Module ${i}: "${extraLine1}"`)
  //           console.log(`XY: Final Line 2 for Module ${i}: "${extraLine2}"`)
  //           // 处理额外行的逻辑（如果需要）
  //         } else {
  //           const extraLine3 = lines[5 + i * 4]
  //           // 处理额外行的逻辑（如果需要）
  //         }
  //       }
  //     } else {
  //       console.log(`XY: ${targetFileName} not found in the ZIP file.`)
  //     }
  //   } else {
  //     console.log("XY: bulk2 does not exist in zipList.")
  //   }
  //   return false
  // }


  // get report data
  const getReportFunc = () => {
    const { attachment, toothlist, ipr } =
      caseManagement.getAttachmentIPRReport()

    const { lowlist, uplist } = toothlist

    const list = lowlist.concat(uplist)
    dispatch(
      setReportData({
        attachment,
        toothlist: list,
        iprData: ipr,
      }),
    )
  }
 
  const [preparation, setPreparation] = useState<boolean>(false)
  // AttachmentStage
  // const {
  //   userService: {
  //     clinicalSettings: { AttachmentStage },
  //   },
  // } = useAppSelector((state: RootState) => state)
  // // attachment stage
  // useEffect(() => {
  //   if (preparation) {
  //     // set attachment stage
  //     attachmentModule.setAttachStep(+AttachmentStage)
  //     // 检查 hash 是否以 #uassit 结尾
  //     if (location.hash === "#uassist") {
  //       switchEditCallBack()
  //     }
  //   }
  // }, [preparation, AttachmentStage])

  //console.log('1111111111',open,ProgressMsg,isShowProgress);

  return (
    (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isShowProgress}
      >
        <Box id={"PrograssBar"} sx={{ width: "40%" }}>
          {ProgressMsg}
          <LinearProgress />
        </Box>

        <Dialog
          sx={{
            width: 510,
            height: 330,
            alignSelf: "center",
            justifySelf: "center",
          }}
          open={alert}
        >
          <DialogTitle
            sx={{
              fontsize: "20px",
              fontstyle: "normal",
              fontweight: 500,
              lineheight: "160%",
              letterspacing: " 0.15px",
            }}
          >
            Self-planned cases must complete pre-setup in uDesign 10
          </DialogTitle>
          <DialogContent
            sx={{
              fontsize: "16px",
              fontstyle: "normal",
              fontweight: 400,
              lineheight: "150%",
              letterspacing: "0.15px",
            }}
          >
            To view or modify a self planned case in uDesign Cloud, you must
            first <strong>open the case in uDesign desktop software</strong> and
            complete tooth identification and segmentation.
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setAlert(false)
                navigate(-1)
              }}
              sx={{
                textTransform: "uppercase",
              }}
              size="medium"
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Backdrop>
    )
  )
}
